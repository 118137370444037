import * as React from 'react';
import cn from 'classnames';
import ProductWithInfoCard from './ProductWithInfoCard/ProductWithInfoCard';
import AppContext from 'contexts/AppContext/AppContext';
import { CmsProductPage, Taxon } from 'contexts/AppContext/types';
import Chip from 'styleguide/components/Chip/Chip';
import { useLocation } from 'react-router-dom';
import { Tab, TabGroup, TabList, TabPanel, TabPanels, Transition } from '@headlessui/react';
import './MainSection.css';
import { attachPageToTaxons } from './utils';
import { useTabSelection } from './hooks/use-tab-selection';
import { useScroll } from './hooks/use-scroll';

export interface TaxonWithCmsPage extends Taxon {
  cmsPage: CmsProductPage;
  taxons?: TaxonWithCmsPage[];
}

const MainSection = () => {
  const appContext = React.useContext(AppContext);
  const taxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Navbar');
  const landingTaxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Landing');

  const allTaxons = React.useMemo(
    () =>
      attachPageToTaxons(
        taxonomy.taxons as TaxonWithCmsPage[],
        appContext.store.cmsPages as CmsProductPage[],
      ),
    [taxonomy],
  );

  const landingTaxons = React.useMemo(() => {
    if (landingTaxonomy?.taxons != null) {
      return attachPageToTaxons(
        landingTaxonomy.taxons as TaxonWithCmsPage[],
        appContext.store.cmsPages as CmsProductPage[],
      );
    }
    return [];
  }, [landingTaxonomy]);

  const location = useLocation();
  const { selectedTabIndex, setSelectedTabByIndex, setSelectedTabByTaxonName } = useTabSelection(
    allTaxons,
    location,
  );

  const { tabListRef, isScrolledLeft, isScrolledRight } = useScroll(selectedTabIndex);

  const renderChip = (title, selected, onClick) => (
    <Chip
      title={title}
      onClick={onClick}
      textColor={selected ? 'text-default font-bold' : ''}
      backgroundColor={selected ? '!bg-gray-50' : ''}
      className="mr-5 cursor-pointer hover:bg-neutral-50 focus:bg-neutral-50"
    />
  );

  return (
    <div className="mt-6 mb-16">
      <TabGroup selectedIndex={selectedTabIndex} onChange={setSelectedTabByIndex}>
        <div
          className={cn(
            'gradient-mask-container',
            !isScrolledLeft && 'hide-left-gradient',
            !isScrolledRight && 'hide-right-gradient',
          )}
        >
          <TabList ref={tabListRef} className="flex overflow-x-scroll whitespace-nowrap scrollbar-hide">
            <Tab key="all">
              {({ selected }) => renderChip('All', selected, () => setSelectedTabByIndex(0))}
            </Tab>
            {allTaxons.map(taxon => (
              <Tab key={taxon.name}>
                {({ selected }) =>
                  renderChip(taxon.name, selected, () => setSelectedTabByTaxonName(taxon.name))
                }
              </Tab>
            ))}
          </TabList>
        </div>
        <TabPanels className="mt-4">
          <TabPanel>
            <Transition
              as="div"
              appear
              show={selectedTabIndex === 0}
              enter="transition-opacity duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ul className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
                {allTaxons.flatMap(
                  taxon =>
                    taxon.taxons?.map((subTaxon, subIndex) => (
                      <li className="flex list-none justify-center" key={`${subTaxon.name}_${subIndex}`}>
                        <ProductWithInfoCard taxon={subTaxon} />
                      </li>
                    )),
                )}
                {landingTaxons.map((taxon, index) => (
                  <li className="flex list-none justify-center" key={`${taxon.name}_${index}`}>
                    <ProductWithInfoCard taxon={taxon} />
                  </li>
                ))}
              </ul>
            </Transition>
          </TabPanel>
          {allTaxons.map((taxon, index) => (
            <TabPanel key={taxon.name}>
              <Transition
                as="div"
                appear
                show={selectedTabIndex === index + 1}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ul className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
                  {taxon.taxons?.map((subTaxon, subIndex) => (
                    <li className="flex list-none justify-center" key={`${subTaxon.name}_${subIndex}`}>
                      <ProductWithInfoCard taxon={subTaxon} />
                    </li>
                  ))}
                </ul>
              </Transition>
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default MainSection;
